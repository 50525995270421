import React from 'react';
import procareLogo from './resources/procare_logo.png';

function AppFooterMobile() {

    return (
        <div id="footer" className="columns is-mobile is-vcentered" style={{position: 'fixed', bottom: '12px', width: '100%', backgroundColor: '#DEDDDC', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px'}}
        >
                <div className="column is-6" style={{padding: 0, margin: 0 }}>
                    <div style={{backgroundColor: 'rgb(255,157,0)', paddingTop: '8px', paddingRight: '20px', paddingBottom: '5px', paddingLeft: '10px',
                                    borderTop: 'rgb(255,188,64) 2px solid', borderBottom: 'rgb(208,150,0) 2px solid', maxWidth: '180px'}}>
                        <h2 style={{color: 'color: rgb(0,57,118)', lineHeight: '12px', fontWeight: 'bold', fontSize: '12px', fontFamily: 'Fira Sans, sans-serif', marginBottom: '0'}}>
                            Proventia PROCARE™ Drive
                        </h2>
                    </div>
                </div>
                <div className="column is-6 has-text-right" style={{padding: 0, margin: 0 }}>
                    <img className="" src={procareLogo} alt='Proventia' style={{maxWidth: '30%', verticalAlign: 'middle'}}/>
                </div>
    </div>
    );
}

export default AppFooterMobile;
