import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import procareLogo from './resources/procare_logo.png';
import { AuthState } from './reducers/authenticated/authenticatedReducer';

function AppFooter(props: Readonly<{
    authState: AuthState,
    packageJsonClientVersion: string,
}>) {
    const { t } = useTranslation();

    const footerStyles = {
        notAuthenticated: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: '#DEDDDC',
            paddingLeft: '10px',
            paddingRight: '10px',
        } as CSSProperties
        ,
        authenticated: {
            width: '100%',
            backgroundColor: '#DEDDDC',
            paddingLeft: '12px',
            paddingRight: '10px',
        } as CSSProperties,
    };

    return (
        <div id="footer" style=
        {!props.authState.isAuthenticated ? footerStyles.notAuthenticated : footerStyles.authenticated}
        >
        <div className="column is-fullWidth">
            <div className="columns is-mobile is-vcentered">
                <div className="column is-6">
                    <div className="container">
                        <div className="content has-text-left">
                            <p>
                                {t('Version')}&nbsp;&nbsp;
                                { props.authState.isAuthenticated && (props.authState.groups.admin || props.authState.groups.rd) ?
                                    <>
                                        <a href="/info/procareclient?onlyChangeLog=1" target="_blank">{ props.packageJsonClientVersion }</a>
                                        { props.authState.serverVersion && (
                                            <span>&nbsp;/&nbsp;<a href="/info/procareserver?onlyChangeLog=1" target="_blank">{ props.authState.serverVersion }</a></span>
                                        ) }
                                    </> :
                                    <>
                                        { props.packageJsonClientVersion }
                                        { props.authState.serverVersion && (
                                            <span>&nbsp;/&nbsp;{ props.authState.serverVersion }</span>
                                        ) }
                                    </>
                                }
                            </p>
                        </div>
                    </div>
                </div>
                <div className="column is-6">
                    <div className="content has-text-right">
                        <img className="" src={procareLogo} alt='Proventia' />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AppFooter;
